import { KTCard, KTCardBody } from "_metronic/helpers";
import { removeDuplicates } from "_metronic/helpers/convertData/remove-duplicate";
import { mapValue } from "_metronic/helpers/formatData";
import {
  Input,
  InputDropdown,
  MultiSelectDropdown,
  Slug,
} from "app/components/Form";
import Label from "app/components/Form/Label";
import TextEditor from "app/components/Form/TextEditor";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ItemModel as BrandInterface } from "../../../brand/core/_models";
import { getList as getListBrand } from "../../../brand/core/_requests";
import { ItemModel as ProductCollectionInterface } from "../../../collection/core/_models";
import { getList as getProductCollection } from "../../../collection/core/_requests";
import { defaultInformationProduct, formEditType } from "../../core/_models";

const GeneralSetting = () => {
  const { id } = useParams();
  const formik = useFormikContext<formEditType>();

  const [brands, setBrands] = useState<BrandInterface[]>([]);

  const [productCollections, setProductCollection] = useState<
    ProductCollectionInterface[]
  >([]);

  useEffect(() => {
    // getProductCollection({ limit: 10, page: 1, isActive: true })
    //   .then((x) => setProductCollection(x.data || []))
    //   .catch((x) => console.log(x));

    getListBrand({ limit: 10, page: 1, isActive: true })
      .then((x) => {
        setBrands(x.data || []);
      })
      .catch((x) => console.log(x));
  }, []);

  return (
    <KTCard>
      <KTCardBody>
        <div className="fv-row mb-7">
          <Input
            required
            formik={formik}
            label="Tên"
            placeholder="Tên"
            name="title"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values.title || ""}
          />
        </div>
        <div className="fv-row mb-7">
          <Slug formik={formik} />
        </div>
        {id ? (
          <>
            <div className="fv-row mb-7">
              <Input
                formik={formik}
                label="Short ID"
                placeholder="ShortID"
                name="shortId"
                disabled={true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                values={formik.values.shortId || ""}
              />
            </div>
            <div className="fv-row mb-7">
              <Input
                formik={formik}
                label="ProductID"
                placeholder="ProductID"
                name="productId"
                disabled={true}
                values={formik.values.productId || ""}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="fv-row mb-7">
          <TextEditor
            label={"Mô tả"}
            formik={formik}
            disabled={formik.isSubmitting}
            name="description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={
              formik.values?.informationDetail?.description ||
              formik.values?.description ||
              ""
            }
          />
        </div>

        <div className="fv-row mb-7">
          <TextEditor
            label={"Thông tin"}
            formik={formik}
            disabled={formik.isSubmitting}
            name="information"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={
              formik.values?.informationDetail?.information ||
              formik.values?.information ||
              ""
            }
          />
        </div>

        {/* <div className="fv-row mb-7">
          <TextEditor
            label={"Thông tin"}
            formik={formik}
            disabled={formik.isSubmitting}
            name="information"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={
              formik.values.informationDetail?.information ||
              defaultInformationProduct
            }
          />
        </div> */}

        <div className="fv-row mb-7">
          <TextEditor
            label={"Mô tả ngắn"}
            formik={formik}
            disabled={formik.isSubmitting}
            name="shortDescription"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            values={formik.values?.informationDetail?.shortDescription || ""}
          />
        </div>

        <div className="fv-row mb-7">
          <InputDropdown
            label="Thương hiệu"
            classNameLabel="form-label fs-6 fw-bold"
            classNameDropdown="form-select form-select-solid fw-bolder"
            options={removeDuplicates(
              Object.keys(formik.values.brand || {})?.length
                ? mapValue([formik.values.brand, ...brands])
                : mapValue([...brands]),
              "value"
            )}
            formik={formik}
            name="brandId"
            values={formik.values.brandId || null}
            onSearch={(e: string) => {
              getListBrand({ limit: 10, page: 1, search: e, isActive: true })
                .then((x) => {
                  setBrands(x.data || []);
                })
                .catch((x) => console.log(x));
            }}
            // onChange={formik.handleChange}
          />
        </div>

        <div className="fv-row mb-7">
          <MultiSelectDropdown
            formik={formik}
            options={removeDuplicates(
              mapValue([
                ...(formik.values.collections || []),
                ...productCollections,
              ]),
              "value"
            )}
            label="Bộ sưu tập"
            onSearch={(search) => {
              getProductCollection({
                limit: 10,
                search,
                page: 1,
                isActive: true,
              })
                .then((x) => setProductCollection(x.data || []))
                .catch((x) => console.log(x));
            }}
            name="collectionIds"
            onSelect={(values) => {
              formik.setFieldValue("collectionIds", values);
            }}
            onRemove={(values) => {
              formik.setFieldValue("collectionIds", values);
            }}
            values={formik.values.collectionIds || []}
          />
        </div>
        <div className="fv-row mb-7 relative">
          <Label>Phương tiện</Label>
          <ImageUploadGlobal
            formik={formik}
            multiple={true}
            name="gallery"
            path="/products"
          />
        </div>
      </KTCardBody>
    </KTCard>
  );
};

export default GeneralSetting;
