import { defaultInformationProduct } from "app/pages/sale-management/product/core/_models";
import { CKEditor } from "ckeditor4-react";
import clsx from "clsx";
import { useEffect, useState } from "react";

interface PropsType {
  formik?: any;
  classNameLabel?: string;
  label?: string;
  htmlFor?: string;
  name?: any;
  values?: string;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  onChangeEditor?: (e: any) => void;
}

const PUBLIC_URL = process.env.PUBLIC_URL;

export const TextEditor = (props: PropsType) => {
  const {
    label,
    htmlFor,
    classNameLabel,
    formik,
    disabled = false,
    name,
    values,
    onBlur,
    onChangeEditor,
    onChange,
    required,
    isLoading,
  } = props;

  const [data, setData] = useState(values);
  const [instance, setInstance] = useState<any>(null);

  const config = {
    versionCheck: false,
    filebrowserBrowseUrl: PUBLIC_URL + "/media",
    filebrowserImageBrowseUrl: PUBLIC_URL + "/media",
    uploadUrl: process.env.REACT_APP_API_URL_UPLOAD + "/upload/ckfinder",
    extraPlugins: ["justify", "toc", "font"],
  };

  useEffect(() => {
    // const updateDate = setTimeout(() => {
    if (data) {
      formik.setFieldValue(name, data);
    }
    // }, 500);
    // return () => clearTimeout(updateDate);
  }, [data]);

  useEffect(() => {
    if (values && instance && instance.editor && !isLoading) {
      // if (values && instance && instance.editor && !isLoading) {
      if (!instance.editor.getData()) instance?.editor.setData(values || "");
    }
  }, [values, instance, isLoading]);

  return (
    <div className="mb-4">
      <label
        className={clsx("fw-bold fs-6 mb-2", classNameLabel?.toString(), {
          required: required,
        })}
        htmlFor={htmlFor}
      >
        {label}
      </label>
      <CKEditor
        config={config}
        name={name}
        readOnly={disabled}
        editorUrl="https://cdn.active.vn/ckeditor/ckeditor.js"
        onChange={(instance: any) => {
          const html = instance.editor.getData();
          onChangeEditor?.(html);
          setData(html);
        }}
        onInstanceReady={(editorInstance: any) => {
          setInstance(editorInstance);
        }}
      />
      <input
        type="hidden"
        id={htmlFor}
        name={name}
        value={data}
        onChange={onChange}
        onBlur={onBlur}
      />
      {formik?.touched[name] && formik?.errors[name] && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <span role="alert">{formik?.errors[name]}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
