import { useQueryResponse } from "_metronic/helpers/provider/QueryResponseProvider";
import { swallError } from "_metronic/helpers/swal";
import { createSuccess, updateSuccess } from "_metronic/helpers/toastify";
import { PageTitle } from "_metronic/layout/core";
import { Switcher } from "app/components/Form";
import { StickyEditSidebar } from "app/components/Sidebar";
import CustomTabs from "app/components/Tabs";
import ImageUploadGlobal from "app/components/Upload/ImageUploadGlobal";
import { TEditPage } from "constants/type";
import { PERCENTAGE_VALIDATE } from "constants/validate-rule";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";

import { useVoucherCondition } from "../context/ConditionProvider";
import { initialData, ItemModel } from "../core/_models";
import { createItem, editItem, getItem } from "../core/_requests";
import AdvanceSetting from "./AdvanceSetting";
import GeneralSetting from "./GeneralSetting";

const EditPage: FC<TEditPage> = ({ breadcrumbs }) => {
  const { id } = useParams();
  const [data, setData] = useState<ItemModel>(initialData);
  const { condition } = useVoucherCondition();

  const navigate = useNavigate();
  const { isLoading } = useQueryResponse();

  const validation = Yup.object().shape({
    ...PERCENTAGE_VALIDATE,
  });

  const submit = async (query: any) => {
    try {
      if (query.discountType === "percentage" && query.discountValue > 100) {
        swallError("Giá trị giảm không phù hợp", "Phần trăm phải nhỏ hơn 100");

        return;
      }

      if (id) {
        const { code, owners, ...newQuery } = query;
        await editItem(newQuery, id).then(updateSuccess);
      } else {
        const { owners, ...newQuery } = query;

        await createItem(newQuery).then((data) => {
          if (data.statusCode > 300) {
            return;
          }

          createSuccess();
          navigate("/vouchers");
        });
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  useEffect(() => {
    if (id) {
      initPage(id);
    }
  }, [id]);

  const initPage = async (id: string) => {
    getItem(id)
      .then((x) =>
        setData({
          ...x,
          conditionType: x.condition
            ? "custome"
            : x.conditionBuyTime?.isFirstApplied
            ? "first_time"
            : x.conditionBuyTime?.isNextApplied
            ? "next_time"
            : "",
          conditionSelected: x?.conditionSelected
            ? JSON.parse(x?.conditionSelected)
            : {},
          ownerIds: x.owners?.map((item) => ({
            label: item.fullname,
            value: item.id,
          })),
        })
      )
      .catch((e) => {
        console.log(e);
        navigate("/vouchers");
      });
  };

  const handleConditionBuyTime = (values: ItemModel) => {
    const type = values.conditionType;

    if (type === "next_time" || type === "first_time") {
      return {
        isNextApplied: type === "next_time",
        isFirstApplied: type === "first_time",
      };
    }

    return null;
  };

  const filterEmptyCondition = () => {
    if (condition) {
      // check if condition is "and" or "or"
      if (
        Object.keys(
          (Object.values(condition)?.[0] as Array<any>)?.[0] || {}
        )?.[0] === "value"
      ) {
        let flatCondition = {};

        if (Object.keys(condition).length !== 0) {
          flatCondition = {
            [Object.keys(condition)?.[0]]: (
              Object.values(condition)?.[0] as Array<any>
            )[0]?.value?.filter(
              (item: any) => item.type !== "" || item.value !== ""
            ),
          };
        }

        return JSON.stringify(flatCondition);
      } else {
        if (condition[Object.keys(condition)?.[0]]?.length > 0) {
          condition[Object.keys(condition)?.[0]]?.forEach((item: any) => {
            item[Object.keys(item)[0]] = item[Object.keys(item)[0]]?.filter(
              (child: any) => child.type !== "" || child.value !== ""
            );
          });

          condition[Object.keys(condition)?.[0]]?.forEach(
            (item?: any, index?: any) => {
              if (item[Object.keys(item)[0]]?.length === 0) {
                condition[Object.keys(condition)?.[0]].splice(index, 1);
              }
            }
          );

          return JSON.stringify(condition);
        } else {
          return null;
        }
      }
    }
  };

  // filterEmptyCondition();

  const formik = useFormik({
    initialValues: data,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      console.log("isactive", values.isActive);
      setSubmitting(true);
      try {
        const query = {
          ...values,
          id: values?.id || null,
          startedDate: values?.startedDate || Date.now(),
          expiredDate: values?.expiredDate || Date.now(),
          avaiableForGuess: Boolean(values?.avaiableForGuess || false),
          ownerIds: values?.ownerIds?.map((item) => item.value),
          condition: condition ? filterEmptyCondition() : null,
          platform: [process.env.REACT_APP_PLATFORM],
          conditionSelected: JSON.stringify(values.conditionSelected),
          // targetModel:
          //   values?.targetModel === "All" ? null : values?.targetModel,
          // target:
          //   values?.targetModel === "All"
          //     ? null
          //     : values?.target?.map((item?: any) => item.value),
          conditionBuyTime: handleConditionBuyTime(values),
        };

        await submit(query);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const tabs = [
    {
      id: "general",
      name: "Thông tin cơ bản",
      content: () => <GeneralSetting formik={formik} />,
    },
    {
      id: "advance",
      name: "Điều kiện",
      content: () => <AdvanceSetting formik={formik} />,
    },
  ];

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>
        {data?.title ? data.title : "Thêm mới"}
      </PageTitle>

      <form className="form" onSubmit={formik?.handleSubmit} noValidate>
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid mb-10 mb-lg-0 me-lg-7 me-xl-10">
            <CustomTabs
              className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
              items={tabs}
            />
          </div>
          <div className="flex-lg-auto min-w-lg-300px">
            <StickyEditSidebar
              formik={formik}
              isLoading={isLoading}
              isEdit={!!id}
              onSubmit={() => formik.handleSubmit()}
            >
              <ImageUploadGlobal
                formik={formik}
                className="mx-auto w-100 border mb-10"
                path="/voucher"
              />

              {/* <div className="col-md-12 mb-7">
                <MultiSelectDropdown
                  formik={formik}
                  options={Object.keys(PlatformEnum).map((item) => ({
                    label: PlatformEnum[item as keyof typeof PlatformEnum],
                    value: item,
                  }))}
                  label="Nền tảng"
                  name="platform"
                  onSelect={(values) => {
                    formik.setFieldValue("platform", values);
                  }}
                  onRemove={(values) => {
                    formik.setFieldValue("platform", values);
                  }}
                  values={formik.values.platform}
                />
              </div> */}

              <div className="fv-row mb-7">
                <Switcher
                  label="Khách vãng lai sử dụng"
                  name="avaiableForGuess"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={!!formik.values.avaiableForGuess}
                />
              </div>

              <div className="fv-row mb-7">
                <Switcher
                  label="Làm quà tặng"
                  name="isGift"
                  direction="horizontal"
                  onChange={formik.handleChange}
                  value={!!formik.values.isGift}
                />
              </div>
            </StickyEditSidebar>
          </div>
        </div>
      </form>
    </>
  );
};

export default EditPage;
